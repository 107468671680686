/* You can add global styles to this file, and also import other style files */

@import './assets/css/bootstrap-grid.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*
	REDESIGN
*/

mat-nav-list.nav-list {
  width: 100%;

  /* .mat-list-item-content {
		border-bottom: 1px solid #1F3A5E !important;
	} */
}

mat-sidenav-content {
  background: linear-gradient(136deg, #142439 0%, #1F3A5E 100%);
}

mat-card {
  margin: 15px 0;
  padding: 24px !important;
  border-radius: 20px !important;
  background: #244572 !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20) !important;

  h2 {
    margin-bottom: 18px !important;
  }

  mat-form-field[appearance=outline]:last-child {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

mat-list-option {
  margin-bottom: 6px;
  background-color: #2a5185;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 14px;
  overflow: hidden;
  height: auto !important;
  min-height: 48px !important;
  display: flex !important;
}

mat-list-option.mat-list-single-selected-option {
  border: 1px solid var(--grn-c-5-ecbe, #53F0B8);
  background-color: #2a5185 !important;
}

mat-radio-group {
  display: flex;
  gap: 24px;
}

.btn-alert {
  color: #CC2936;
}

.customer-dashboard {
  overflow: visible !important;

  .mat-tab-body-wrapper {
    overflow: visible !important;

    mat-tab-body {
      padding: 15px !important;
    }
  }
}


mat-dialog-container {
  border-radius: 15px !important;
  background: var(--dunkel-blau-244572, #244572) !important;
  box-shadow: 0px 0px 22px -11px #FEFCFD !important;
}

mat-list.list-media-objects {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

.heading-wrapper {
  display: flex;
  align-items: center;
  margin: 24px 0;


  h1 {
    margin: 0 !important;
    line-height: inherit;
  }
}

table[mat-table] {
  border-radius: 30px 30px 0px 0px !important;
  overflow: hidden;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20);

  thead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    background: var(--bg-kacheln, linear-gradient(134deg, rgba(255, 255, 255, 0.02) 0%, rgba(254, 252, 253, 0.01) 100%));
    box-shadow: 0px 4px 18px -5px rgba(254, 252, 253, 0.10);

    th {
      background: #192B43 !important;
    }
  }

  td {
    background: #1A293E;
  }
}

mat-stepper.mat-stepper-horizontal,
mat-stepper.mat-stepper-vertical {
  background-color: transparent !important;
}


dp-day-calendar {
  .dp-day-calendar-container {
    background: transparent !important;
  }

  .dp-calendar-nav-container {
    border: none !important;

    .dp-nav-header-btn {
      background-color: transparent !important;
      color: #fff;
    }

    .dp-calendar-nav-left,
    .dp-calendar-nav-right {
      color: #fff;
      background-color: transparent !important;
    }
  }

  .dp-calendar-weekday {
    color: rgba(255, 255, 255, 0.8) !important;
    margin: 4px;
  }

  .dp-calendar-day {
    margin: 4px;
  }

  .dp-selected {
    background-color: #53F0B8 !important;
    color: #244572 !important;
  }

  .dp-current-day {
    border-color: #53F0B8 !important;
    border-width: 3px !important;

  }

  .dp-calendar-wrapper {
    border: none !important;
  }
}

mat-radio-group.challenge-category-selection {
  mat-radio-button {
    background-color: #2a5185;
    border-radius: 20px;
    overflow: hidden;
    font-size: 24px;
    font-style: normal;
    line-height: 120%;
    border: 1px solid transparent;

    label.mat-radio-label {
      span.mat-radio-container {
        display: none !important;
      }

      span.mat-radio-label-content {
        padding: 0;
      }
    }

    .radio-button-content {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 24px;
      text-align: center;

      .img-wrapper {
        width: 64px;
        height: 64px;
        background-color: #fff;
        padding: 8px;
        border-radius: 12px;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  mat-radio-button.mat-radio-checked {
    border-color: var(--grn-c-5-ecbe, #53F0B8);
  }
}

mat-expansion-panel {
  background: var(--dunkel-blau-244572, #244572) !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20) !important;
}

mat-datepicker-content {
  background: #1F3A5E !important;
}

.mat-select-panel,
.mat-menu-panel,
.mat-autocomplete-panel {
  background: #1F3A5E;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fff;
  background: #2D4E7A;
}

mat-tab-group.tabs-customer-progress {
  .mat-tab-label {
    height: 64px !important;
    padding: 0 !important;
    min-width: 100px !important;
  }
}

/*
	BOOTSTRAP UTILS
*/
.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.spacer {
  flex: 1 1 auto;
}

/*
	TYPOGRAPHY
*/
h1 {
  margin-top: 24px !important;
}

.text-red {
  color: #f00;
}

.text-empty {
  color: rgba(#fff, 0.5);
}

/*
	ANGULAR MATERIAL COMPONENTS
*/
mat-toolbar {
  z-index: 999 !important;
}

mat-form-field {
  width: 100%;
}


table.mat-table {
  width: 100%;
}

button {
  mat-spinner {
    display: inline-block !important;
    margin-right: 8px;
  }
}

/*
	SNACKBAR
*/
.red-snackbar,
.green-snackbar {
  color: #fff;
}

.red-snackbar {
  background-color: red;
}

.green-snackbar {
  background-color: green;
}

/*
	COMPONENTS
*/

app-file-upload {
  display: block;
}

a.unstyled {
  color: inherit;
  text-decoration: inherit;
}

.button-bar,
.button-bar-left {
  margin: 16px 0;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.button-bar-left {
  justify-content: start;
}

.button-bar {
  margin: 16px 0;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.fab-bottom-right {
  position: fixed !important;
  right: 16px;
  bottom: 16px;
  z-index: 100;
}


.fab-container {
  position: fixed !important;
  bottom: 16px;
  right: 16px;
  top: auto;
  left: auto;
  z-index: 999;

  button {
    display: block;
  }

  button:not(:last-child) {
    margin-bottom: 12px;
  }
}

.row.card-stretch {
  >* {
    display: flex;

    mat-card {
      flex: 1;
    }
  }
}

/*
	OTHER
*/
.one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100%;
}